<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="mpfs" class="elevation-1" page-count="5" :search="search" :loading="loading">
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <div v-if="group === 'DEVELOPMENT' || group === 'MANAGEMENT' || group === 'SALES'">
                                    <v-chip
                                        class="ma-2 "
                                        color="warning"
                                        v-if="item.dt_approve === '1900-01-01 00:00:00.000' && item.dt_reject === '1900-01-01 00:00:00.000'"
                                        @click="showApprove1(item)"
                                    >
                                        PENDING
                                    </v-chip>
                                    <v-chip
                                        class="ma-2 text-white"
                                        color="success"
                                        v-if="item.dt_approve != '1900-01-01 00:00:00.000' && item.dt_reject === '1900-01-01 00:00:00.000'"
                                        @click="showDetail(item)"
                                    >
                                        APPROVED
                                    </v-chip>
                                    <v-chip
                                        class="ma-2 text-white"
                                        color="error"
                                        v-if="item.dt_approve === '1900-01-01 00:00:00.000' && item.dt_reject != '1900-01-01 00:00:00.000'"
                                        @click="showDetail(item)"
                                    >
                                        REJECTED
                                    </v-chip>
                                </div>
                                <div v-else>
                                    <v-chip
                                        class="ma-2"
                                        color="warning"
                                        v-if="item.dt_approve === '1900-01-01 00:00:00.000' && item.dt_reject === '1900-01-01 00:00:00.000'"
                                        @click="showApprove1(item)"
                                    >
                                        PENDING
                                    </v-chip>
                                    <v-chip
                                        class="ma-2"
                                        color="info"
                                        v-if="item.dt_approve != '1900-01-01 00:00:00.000' && item.dt_aprv3 === '1900-01-01 00:00:00.000' && item.dt_reject === '1900-01-01 00:00:00.000'"
                                        @click="showApprove2(item)"
                                    >
                                        READY TO APPROVE 2
                                    </v-chip>
                                    <v-chip
                                        class="ma-2 text-white"
                                        color="success"
                                        v-if="item.dt_approve != '1900-01-01 00:00:00.000' && item.dt_reject === '1900-01-01 00:00:00.000'"
                                        @click="showDetail(item)"
                                    >
                                        APPROVED
                                    </v-chip>
                                    <v-chip
                                        class="ma-2 text-white"
                                        color="error"
                                        v-if="item.dt_approve === '1900-01-01 00:00:00.000' && item.dt_reject != '1900-01-01 00:00:00.000'"
                                        @click="showDetail(item)"
                                    >
                                        REJECTED
                                    </v-chip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    max-width="600px"
                >
                    <v-card class="p-3 rounded-xl">
                        <v-card-title class="text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="10">
                                <h5 style="font-weight:700; color: green;">
                                    Detail MPF ID of {{detail ? detail.mpf_id : ''}}
                                </h5>
                                </v-col>
                            </v-row>  
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="fill-height">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="2" md="2">
                                        <v-icon x-large color="success" class="text-center">
                                            mdi-clock-outline
                                        </v-icon>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <h6 class="text-center">
                                            Subject : {{detail ? detail.cat_desc : ''}}
                                        </h6>
                                        <h6 class="text-center" style="color: #053d76; font-weight: 600">
                                            <b>TR. Date : {{detail ? detail.tr_date : ''}}</b>
                                        </h6>
                                        <h6 class="text-center">
                                            CreatedBy : {{detail ? detail.userid : ''}}
                                        </h6>
                                        <h6 class="text-center">
                                            To : {{detail ? detail.target_id : ''}}
                                        </h6>
                                        
                                        <p class="text-center mb-0">Attachment : <v-btn color="primary" @click="downloadFile(detail)">{{detail ? detail.attach_path : ''}}</v-btn></p>
                                        <p class="text-center mt-0">Description : {{detail ? detail.remark1 : ''}}</p>
                                    </v-col>
                                </v-row> 
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center" v-if="group === 'DEVELOPMENT' || group === 'MANAGEMENT'">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l text-white p-4" color="#003871" @click="approve()">Approve</v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l text-white p-4" color="error" @click="reject()">Reject</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialog_detail"
                    persistent
                    max-width="600px"
                >
                    <v-card class="p-3 rounded-xl">
                        <v-card-title class="text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="10">
                                <h5 style="font-weight:700; color: green;">
                                    Detail MPF ID of {{detail ? detail.mpf_id : ''}}
                                </h5>
                                </v-col>
                            </v-row>  
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="fill-height">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="2" md="2">
                                        <v-icon x-large color="success" class="text-center">
                                            mdi-clock-outline
                                        </v-icon>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <h6 class="text-center">
                                            Subject : {{detail ? detail.cat_desc : ''}}
                                        </h6>
                                        <h6 class="text-center" style="color: #053d76; font-weight: 600">
                                            <b>TR. Date : {{detail ? detail.tr_date : ''}}</b>
                                        </h6>
                                        <h6 class="text-center">
                                            CreatedBy : {{detail ? detail.userid : ''}}
                                        </h6>
                                        <h6 class="text-center">
                                            To : {{detail ? detail.target_id : ''}}
                                        </h6>
                                        
                                        <p class="text-center mb-0">Attachment : <v-btn color="primary" @click="downloadFile(detail)">{{detail ? detail.attach_path : ''}}</v-btn></p>
                                        <p class="text-center mt-0">Description : {{detail ? detail.remark1 : ''}}</p>
                                    </v-col>
                                </v-row> 
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l text-white p-4" color="#003871" @click="dialog_detail = false">OKE</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialog_aprv2"
                    max-width="600px"
                >
                    <v-card class="p-3 rounded-xl">
                        <v-card-title class="text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="10">
                                <h5 style="font-weight:700; color: green;">
                                    Detail MPF ID of {{detail ? detail.mpf_id : ''}}
                                </h5>
                                </v-col>
                            </v-row>  
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="fill-height">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="2" md="2">
                                        <v-icon x-large color="success" class="text-center">
                                            mdi-clock-outline
                                        </v-icon>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-2">
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <h6>
                                            Subject : {{detail ? detail.cat_desc : ''}}
                                        </h6>
                                        <h6 class="text-center" style="color: #053d76; font-weight: 600">
                                            <b>TR. Date : {{detail ? detail.tr_date : ''}}</b>
                                        </h6>
                                        <h6>
                                            CreatedBy : {{detail ? detail.userid : ''}}
                                        </h6>
                                        <h6>
                                            To : {{detail ? detail.target_id : ''}}
                                        </h6>
                                        
                                        <p class="mb-0">Attachment : <v-btn color="primary" @click="downloadFile(detail)">{{detail ? detail.attach_path : ''}}</v-btn></p>
                                        <p class="mt-5">Description : {{detail ? detail.remark1 : ''}}</p>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <h6 class="blue-lcd">Approval CC/BCC:</h6>
                                        <!-- <div class="d-flex"> -->
                                        <v-checkbox
                                            v-for="(item, j) in target_ids" :key="j"
                                            v-model="target_id"
                                            :label="item.label"
                                            :value="item.value"
                                            class="text-h6"
                                            hide-details
                                        ></v-checkbox>
                                        <!-- </div> -->
                                    </v-col>
                                </v-row> 
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l text-white p-4" color="#003871" @click="approve2()">Approve</v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l text-white p-4" color="error" @click="reject()">Reject</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'MPF',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'MPF List',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            headers: [
                { text: 'ID', value: 'mpf_id' },
                { text: 'Date Created', value: 'tr_date' },
                { text: 'From', value: 'userid' },
                { text: 'To', value: 'target_id' },
                { text: 'Subject', value: 'cat_desc' },
                { text: 'Status', align: 'center', value: 'status' }
            ],
            mpfs: [],
            search: '',
            group: '',
            sales_id: '',
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            dialog: false,
            dialog_detail: false,
            detail: null,
            targets: [],
            target_ids: [],
            target_id: [],
            dialog_aprv2: false,
        }
    },
    mounted(){
        this.$store.dispatch('setOverlay', false)
        this.getGroupUser()
    },
    methods:{
        getEnvConf(){

            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i]['var_id'] === 'SALESID') {

                        if (res.data.data[i]['var_value'] != null) {

                            this.sales_id = res.data.data[i]['var_value']
                            this.getMPF()

                        }
                    }
                }

                if (this.sales_id === '' || this.sales_id === null) {
                    this.getMPF()
                }

            })
        },
        async getGroupUser(){
            await axios.get(`${process.env.VUE_APP_URL}/api/get_group_user?entity_id=SR&appl_id=WEBSR`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.group = res.data.data
                this.getEnvConf()

            });
        },
        async getMPF(){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/mpf?groupid=${this.group}&salesid=${this.sales_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false

                this.mpfs = res.data
            });
        },
        showDetail(item){
            this.detail = item
            this.dialog_detail = true
        },
        async showApprove1(item){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/mpf/show?id=${item.mpf_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.dialog = true
                this.detail = item

                // for (var i = 0; i < res.data.target.length; i++) {
                //     this.targets.push(res.data.target[i].target_id);
                // }
            });
        },
        downloadFile(item){
            window.open(process.env.VUE_APP_URL + '/sr/img/mpf/' + item.attach_path, '_blank')
        },
        async approve(){
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/mpf/approve`, {
                'id': this.detail.mpf_id,
                'arr': this.targets
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.$store.dispatch('setOverlay', false)
                this.getMPF()
                this.dialog = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve MPF",
                    visible: true
                };
            }).catch(err => {
                console.log(err.response);
            })
        },
        async reject(){
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/mpf/reject`, {
                'id': this.detail.mpf_id
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.$store.dispatch('setOverlay', false)
                this.getMPF()
                this.dialog = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Reject MPF",
                    visible: true
                };
            }).catch(err => {
                console.log(err.response);
            })
        },
        async showApprove2(item){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/mpf/show?id=${item.mpf_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.dialog_aprv2 = true
                this.detail = item
                for (var i = 0; i < res.data.target.length; i++) {
                    this.target_ids.push({
                        label: res.data.target[i].descr,
                        value: res.data.target[i].target_id
                    });
                }
            });
        },
        async approve2(){
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/mpf/approve`, {
                'id': this.detail.mpf_id,
                'arr': this.target_id
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.$store.dispatch('setOverlay', false)
                this.getMPF()
                this.dialog_aprv2 = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve MPF",
                    visible: true
                };
            }).catch(err => {
                console.log(err.response);
            })
        }
    },
    watch: {
    }
}
</script>
  